import { axiosInstance } from "@/api/http";

export const confirmNumyLogin = async (phoneNumber: string, email: string) => {
  try {
    const userToken = localStorage.getItem('userToken');
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }

    const data = { phoneNumber, email };

    await axiosInstance.post('/whatsapp-messages/successful-login', data, { headers });

  } catch (error) {
    console.error(error);
    throw new Error('NumyLoginService - confirmNumyLogin: No se pudo confirmar el login');
  }
}