import FoodTagsRecommendator from "@/components/FoodTagsRecommendator/FoodTagsRecommendator";
import { CategoryIds, PredispositionIds, Recommendation, SubcategoryIds } from "@/pages/results/result-constants";
import { buildFoodTagArgumentsUrl, parseRecommendation, replacePRSOR } from "@/pages/results/ResultPage";
import { PredispositionTypeObj } from "@/pages/results/ResultsComponentsModel";
import { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

export interface ButtonForGroup {
  id: string,
  title: string,
  description: null | string[],
  interpretation: null | {
    [key in PredispositionIds]: string
  },
  recommendation?: PredispositionTypeObj
}


export const ButtonsArrayComponent = (
  {
    subcategoryId, buttons, predisposition, prs
  }: {
    subcategoryId: SubcategoryIds;
    buttons: ButtonForGroup[];
    predisposition: PredispositionIds | null;
    prs: number | null
  }
) => {

  const [selectedBody, setSelectedBody] = useState<ButtonForGroup | null>(buttons[0]);

  return (
    predisposition !== null && prs !== null ?
      <div className="d-flex flex-column justify-content-center ">
        <div className="p-2 d-flex flex-column justify-content-center">
          <div className="sbcat-buttons-array mx-auto">
            <ButtonGroup aria-label="sbcat-buttons-array">
              {buttons.map((b, i) =>
                <Button
                  className={`${i === 0 ? 'first' : i === buttons.length - 1 ? 'third' : 'second'}`}
                  style={{
                    borderRadius: buttons.length === 1 ? '14px' : undefined,
                  }}
                  active={selectedBody?.id === b.id}
                  onClick={() => setSelectedBody(b)}
                >
                  {b.title}
                </Button>
              )}
            </ButtonGroup>
          </div>

          {selectedBody ?
            <div className="mx-3 d-flex flex-column justify-content-center text-center">
              {selectedBody.description ?
                selectedBody.description.map((d, i) =>
                  <p
                    key={`buttons-array-description-${i}`}
                    dangerouslySetInnerHTML={{ __html: replacePRSOR(d, prs, prs) }}
                  />
                )
                : null}
              {selectedBody.interpretation ?
                <p
                  style={{ textAlign: 'justify', margin: '0 2rem' }}
                  dangerouslySetInnerHTML={{ __html: replacePRSOR(selectedBody.interpretation[predisposition], prs, prs) }}
                />
                : null}
              {selectedBody.recommendation ?
                <FoodTagsRecommendator
                  prefer={parseRecommendation(selectedBody.recommendation[predisposition], Recommendation.PREFER)}
                  reduce={parseRecommendation(selectedBody.recommendation[predisposition], Recommendation.REDUCE)}
                  avoid={parseRecommendation(selectedBody.recommendation[predisposition], Recommendation.AVOID)}
                  navigateTo={
                    subcategoryId === SubcategoryIds.WELLNESS_IBD ?
                      buildFoodTagArgumentsUrl(CategoryIds.WELLNESS, subcategoryId, predisposition)
                      : undefined
                  }
                  singleColumnRecommendation
                />
                : null
              }
            </div>
            : null
          }

        </div>
      </div>
      : null
  )
}