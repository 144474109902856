import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, useLoadUser } from '@/components/AuthContext';

import Container from 'react-bootstrap/esm/Container';

import LoginForm from '@/pages/login/LoginForm';
import { AuthenticationResult, LoginData, User } from '@/models';

import { getUserToken } from '@/services/auth/auth-service';

import numyLogoBig from '@/assets/icons/numy/numy-logo-big.svg';

import { LOGIN_ERROR_MESSAGES } from '@/utils/constants';
import { useNotifications } from '@/contexts/NotificationContext';
import { confirmNumyLogin, } from '@/services/numy-whatsapp';
import { getCurrentUser, updateNumyLastLogin, updateUserLastLogin } from '@/services/user/user';


const NumyLoginPage = () => {
  const currentUser = useCurrentUser();
  const [user, setUser] = useState<User | null>(currentUser);
  const loadUser = useLoadUser();
  const navigate = useNavigate();

  const { showNotification } = useNotifications();

  const confirmLogins = async () => {
    if (!user?.phoneNumber) {
      throw new Error('UserService - confirmLogins: No se pudo confirmar el login');
    }
    try {
      await Promise.all([
        confirmNumyLogin(user?.phoneNumber.replace('+', ''), user.email),
        updateNumyLastLogin(),
        updateUserLastLogin()
      ]);

    } catch (error) {
      const msg = 'Error al confirmar el login';
      throw new Error(`${msg}: error`);
    }
  }

  useEffect(() => {
    if (user?.id) {
      try {
        confirmLogins();

        showNotification('Tienes la sesión iniciada, puedes chatear con Numy!', 'info');
        navigate('/');
        window.open(`https://wa.me/${process.env.REACT_APP_NUMY_WHATSAPP_ID ?? 56929663134}`, '_blank');

      } catch (error) {
        console.error('Error al confirmar el login', error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const loginUser = async (loginData: LoginData) => {
    try {
      const authenticationData: AuthenticationResult = await getUserToken(loginData);
      const userInfo = await getCurrentUser(authenticationData.AccessToken);

      localStorage.setItem('userToken', authenticationData.AccessToken);

      setUser(userInfo);

      await loadUser();
      
      showNotification('¡Iniciaste sesión para chatear con Numy!', 'success');

    } catch (error) {
      if (error instanceof Error) {
        const notificationVariant = 'danger';
        if (error.message === LOGIN_ERROR_MESSAGES.UNAUTHORIZED) {
          showNotification(
            `${error.message}`,
            notificationVariant);
        } else {
          showNotification(
            LOGIN_ERROR_MESSAGES.PLATFORM_ERROR,
            notificationVariant);
        }
      }
    }
  }

  return (
    <Container
      style={{ height: '100vh', backgroundColor: '#FFF' }}
      className="
        gap-4
        d-flex flex-column
        justify-content-start align-items-center
        "
    >
      <div
        className="p-1 d-flex flex-column align-items-center"
        style={{ width: '200px', marginTop: '100px' }}
      >
        <img src={numyLogoBig} style={{ width: '150px' }} alt="Numy Logo" />

      </div>

      <div className="fw-bold w-75 text-center">
        <span>
          Inicia sesión en Mynu para hablar con Numy por WhatsApp
        </span>
      </div>

      <LoginForm loginUser={loginUser} />
    </Container>
  );
};

export default NumyLoginPage;