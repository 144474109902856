import { useEffect, useMemo, useState } from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import FoodTagsRecommendator from "@/components/FoodTagsRecommendator/FoodTagsRecommendator";
import MainLayout from "@/components/MainLayout/MainLayout";
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";
import StopLight from "@/components/StopLight/StopLight";

import {
  categoryNames, chartType, isReplacePRSText,
  predispositionValues, SubcategoryVitaminsType
} from "../../ResultsComponentsModel";

import {
  CategoryIds,
  PredispositionIds,
  Recommendation
} from "../../result-constants";

import { ReactComponent as PinIcon } from '@/assets/results/vitamins/icons/mynu-pin.svg';
import bgSrc from '@/assets/results/vitamins/images/vitamins.webp';


import { MineralsDescrition, VitaminsDescrition } from "./VitaminMinerals";

import "./VitaminsComponent.scss";
import "../../GeneticResults.scss";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { buildFoodTagArgumentsUrl, getCategoryFromResults, parseRecommendation, replacePRSOR } from "../../ResultPage";
import { loadUserInfo } from "@/store/loader";
import { LoadingPage } from "@/router/LoadingPage";
import { Subcategory } from "@/models";
import CategoryHeader from "../CategoryHeaderComponent";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";


export default function VitaminsComponent() {

  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults, resultsData } = store;

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.VITAMINS), [resultsData]);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="category-container vitamins-component-container"
        style={{
          '--bg-image-src': `url("${bgSrc}")`,
          position: 'relative'

        } as React.CSSProperties}
      >
        <CategoryHeader title={categoryNames[CategoryIds.VITAMINS]} />

        {hasResults() && resultsState ?
          <Container className="px-0 micro-nutrient-container">
            <Container className="p-0 subcategories-cards-container">

              <div className="m-3 text-center"><h3><strong>Deficiencia de Vitaminas</strong></h3></div>

              <div className="cards-container">
                {VitaminsDescrition.map((v, i) => {
                  const subcatInfo = resultsState[v.id];
                  return (
                    subcatInfo.prs_to_1 !== null ?
                      <Card
                        id={v.id}
                        key={`sbcat-vitamins-id-${i}`}
                        className="sbcat-card align-self-md-baseline"
                      >
                        <SubcategoryComponent sb={v} info={subcatInfo} />
                      </Card>
                      : null)
                })}
              </div>
            </Container>

            <Container className="p-0 subcategories-cards-container">
              <div className="m-3 text-center"><h3><strong>Deficiencia de Minerales</strong></h3></div>

              <div className="cards-container">
                {MineralsDescrition.map((m, i) => {
                  const subcatInfo = resultsState[m.id];
                  return (
                    subcatInfo.prs_to_1 !== null ?
                      <Card
                        id={m.id}
                        key={`sbcat-minerals-id-${i}`}
                        className="sbcat-card align-self-md-baseline"
                      >
                        <SubcategoryComponent sb={m} info={subcatInfo} />
                      </Card>
                      : null)
                })}
              </div>
            </Container>
          </Container>
          : <LoadingPage />
        }
        <Card className="bottom-card">
          <Card.Header>
            <div className="svg-container">
              <PinIcon />
            </div>
            <div className="title">
              Recuerda
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              Recuerda que siempre será mejor obtener estos nutrientes a través de los alimentos. Si tienes dudas frente a tus niveles,
              <span className="mynu-stars">{' '}consulta con un profesional</span>.
            </div>
          </Card.Body>

        </Card>
      </div>
    </MainLayout>
  )
}

interface SubcategoryComponentProps {
  sb: SubcategoryVitaminsType,
  info: Subcategory
}

export const SubcategoryComponent = (props: SubcategoryComponentProps) => {
  const { sb, info } = props;

  const {
    id, name, tooltip, prsText,
    percentileOrStoplight, effects,
    examples, mtfhrActivity,
    body
  } = sb;

  const [selectedBody, setSelectedBody] = useState<string | null>('effects');
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <Card.Header className="d-flex flex-column justify-content-center">
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
          <div className="d-flex flex-row align-items-center gap-2">
            <div className="sbcat-title">
              {name}
            </div>
            <div className="sbcat-tooltip">
              <div> ? </div>
            </div>
          </div>
        </OverlayTrigger>

        <div className="mx-auto px-2 percentile-bar-container">
          {percentileOrStoplight === chartType.BAR ?
            <div className="text-center">
              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
              <CustomSliderComponent
                size={'2rem'}
                percent={info.percentile!}
                intervals={['Menor', 'Promedio', 'Mayor']}
                intervalsDescriptions={[
                  'Tienes menos riesgo que la población general',
                  'Tu riesgo es similar al de la población general',
                  'Tienes más riesgo que la población general'
                ]}
              />
            </div>
            :
            <div className="d-flex flex-column align-items-center">
              <div className="text-center" style={{ fontSize: 'medium' }}>
                <strong>
                  <span>Riesgo -{' '}</span>
                  <span
                    style={{
                      color: predispositionValues[info.predisposition].textColor2
                    }}
                  >
                    {''.replace('', predispositionValues[info.predisposition].name)}
                  </span>
                </strong>
              </div>
              <StopLight
                colors={
                  Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].textColor2)
                }
                descriptions={[]}
                titles={['Bajo', 'Medio', 'Alto']}
                changable={false}
                activeIndex={predispositionValues[info.predisposition].id}
              />
            </div>
          }
        </div>
      </Card.Header>
      <CollapseIcon
        open={collapseOpen}
        setOpen={setCollapseOpen}
        iconSize={2}
      >
        <div>
          <Card.Body>
            <div className="p-2 d-flex flex-column justify-content-center">
              <div className="vitamins-buttons-array mx-auto">
                <ButtonGroup aria-label="vitamins-buttons-array">
                  <Button className="first"
                    active={selectedBody === 'effects'}
                    onClick={() => setSelectedBody('effects')}
                  >
                    Efectos
                  </Button>
                  {mtfhrActivity !== null ?
                    <Button className="second"
                      active={selectedBody === 'mtfhr'}
                      onClick={() => setSelectedBody('mtfhr')}>
                      Actividad MTFHR
                    </Button>
                    : null}
                  {body ?
                    <Button className='third'
                      active={selectedBody === 'recommendation'}
                      onClick={() => setSelectedBody('recommendation')}
                    >
                      Recomendación Nutricional
                    </Button>
                    : null}
                </ButtonGroup>
              </div>
              <div className="display-body" style={{ textAlign: 'justify' }}>
                {selectedBody === 'effects' ?
                  <div className="effects">
                    {effects?.map((e, i) =>
                      <p key={`vitamins-subcat-effects-${i}`}>{e}</p>
                    )}

                    {prsText !== null ?
                      isReplacePRSText(prsText) ?
                        prsText[info.predisposition]?.map((t, i) =>
                          <div
                            id={'replace-text-prs-' + i}
                            key={'replace-text-prs-' + i}
                            dangerouslySetInnerHTML={{
                              __html: replacePRSOR(t, info.prs_to_1, info.prs_to_1)
                            }}
                          />
                        )
                        :
                        <div
                          dangerouslySetInnerHTML={{
                            __html: replacePRSOR(prsText, info.prs_to_1, info.prs_to_1)
                          }}
                        />
                      : null}
                  </div>
                  : mtfhrActivity !== null && selectedBody === 'mtfhr' ?
                    <div className="d-flex flex-column gap-4">
                      {
                        info.gene_breakdown?.map((g, i) => {
                          if (g.gene_name === 'MTHFR' && g.variants) {
                            const variant = g.variants.find(v => v.rsid === 'rs1801133');
                            if (variant) {
                              return (
                                variant.risk !== 'unknown' ?
                                  <div className="text-center mb-3">
                                    <span style={{ fontSize: 'medium', fontWeight: 'bolder' }}>
                                      Actividad MTHFR -
                                      <span style={{ color: predispositionValues[variant.risk].textColor2 }}>
                                        {' ' + predispositionValues[variant.risk].name}
                                      </span>
                                    </span>
                                    <StopLight
                                      colors={['#34B692', '#3900B3', '#FE5133']}
                                      titles={Object
                                        .keys(predispositionValues)
                                        .map((k) => predispositionValues[k as PredispositionIds].name)
                                      }
                                      descriptions={[]}
                                      changable={false}
                                      activeIndex={predispositionValues[variant.risk].id}
                                    />
                                  </div>
                                  : null
                              )
                            }
                          }
                          return null;
                        })
                      }
                      {
                        mtfhrActivity.map((act, i) =>
                          <div className="flex flex-col">
                            <div
                              key={`mtfhrActivity-${i}`}
                              dangerouslySetInnerHTML={{ __html: replacePRSOR(act, info.prs_to_1, info.prs_to_1) }}
                            />
                          </div>)
                      }
                    </div>
                    : selectedBody === 'recommendation' ?
                      <FoodTagsRecommendator
                        prefer={parseRecommendation(body[info.predisposition], Recommendation.PREFER)}
                        reduce={parseRecommendation(body[info.predisposition], Recommendation.REDUCE)}
                        avoid={parseRecommendation(body[info.predisposition], Recommendation.AVOID)}
                        navigateTo={
                          buildFoodTagArgumentsUrl(CategoryIds.VITAMINS, id, info.predisposition)
                        }
                        singleColumnRecommendation
                      />
                      :
                      null}
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <ResponsiveCard subtitle={'Genes'} examples={examples} description={""} />
          </Card.Footer>
        </div>
      </CollapseIcon>
    </>
  )
}